<script setup lang="ts">
import AppFormInputError from "@/Components/Shared/forms/AppFormInputError.vue";
import AppFormField from "@/Components/Shared/forms/AppFormField.vue";
import AppFormFieldLabel from "@/Components/Shared/forms/AppFormFieldLabel.vue";
import { computed, nextTick, onMounted, ref, StyleValue, watch } from "vue";
import { defaultFormEmits, defaultInputProps } from "@/Utils/form";
import AppText from "@/Components/Shared/text/AppText.vue";

type Props = {
    isResizeImportant?: boolean;
    isOverflowImportant?: boolean;
    rows?: string | number;
    cols?: string | number;
    limit?: number | undefined;
    autosize?: boolean;
    minHeight?: number;
    maxHeight?: number;
    important?: boolean | string[];
    inputClass?: string;
    modelValue: string | number | readonly string[] | null | undefined;
} & defaultInputProps;

const {
    modelValue = undefined,
    isResizeImportant = false,
    isOverflowImportant = false,
    rows = 3,
    cols = 20,
    limit = undefined,
    autosize = true,
    minHeight = 90,
    maxHeight = 0,
    important = false,
    inputClass = undefined,
} = defineProps<Props>();

const emit = defineEmits(defaultFormEmits);

// data
const maxHeightScroll = ref(false);
const height = ref("auto");
const input = ref();

// computed
const computedStyles: StyleValue = computed(() => {
    if (!autosize) return {};
    return {
        resize: !isResizeImportant ? "none" : "none !important",
        height: height.value,
        overflow: maxHeightScroll.value
            ? "auto"
            : !isOverflowImportant
            ? "hidden"
            : "hidden !important",
    };
});

const internalValue = computed({
    get() {
        return modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const hasReachedLimit = computed(() => {
    if (!limit) {
        return false;
    }
    if (internalValue.value && internalValue.value.length >= limit) {
        emit("invalid");
        return true;
    }

    return false;
});

const isHeightImportant = computed(() => {
    const imp = important;
    return imp || (Array.isArray(imp) && imp.includes("height"));
});

// methods
const resize = () => {
    const important = isHeightImportant.value ? "important" : "";
    height.value = `auto${important ? " !important" : ""}`;
    let contentHeight = input.value.scrollHeight + 1;
    if (minHeight) {
        contentHeight = contentHeight < minHeight ? minHeight : contentHeight;
    }
    if (maxHeight) {
        if (contentHeight > maxHeight) {
            contentHeight = maxHeight;
            maxHeightScroll.value = true;
        } else {
            maxHeightScroll.value = false;
        }
    }
    const heightVal = contentHeight + "px";
    height.value = `${heightVal}${important ? " !important" : ""}`;
};

// lifecycle
onMounted(() => resize());

// watchers
watch(internalValue, () => {
    nextTick(resize);
});
</script>

<template>
    <app-form-field>
        <app-form-field-label
            v-if="label"
            :for="name"
            :required="required"
        >
            {{ label }}
        </app-form-field-label>

        <textarea
            :id="name"
            ref="input"
            v-model="internalValue"
            :class="[
                'block w-full rounded-sm border-gray-300 shadow-sm focus:ring-primary/10 focus:ring-4 duration-75 text-slate-700 placeholder:text-slate-300 disabled:bg-gray-100 disabled:text-slate-700',
                {
                    'border-red-500': errors && errors.length,
                },
            ]"
            :cols="cols"
            :name="name"
            :placeholder="placeholder"
            :required="required"
            :rows="rows"
            :disabled="disabled"
            :style="computedStyles"
            type="text"
            :maxlength="limit ?? undefined"
        ></textarea>

        <div
            v-if="limit"
            class="ml-auto text-right"
        >
            <AppText
                as="span"
                size="xsmall"
                :class="[{ 'text-red': hasReachedLimit }]"
            >
                {{ internalValue ? internalValue.length : 0 }}/{{ limit }}
                karakters
            </AppText>
        </div>

        <AppFormInputError
            v-if="errors && errors.length"
            :errors="errors"
        />

        <AppText
            v-else-if="hint"
            class="mt-1"
            size="small"
            color="grayLight"
        >
            {{ hint }}
        </AppText>
    </app-form-field>
</template>
